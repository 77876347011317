<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Member Tier Detail</h1>
        </div>
        <div class="title-tabs mt-3">Member Tier Info</div>
        <b-tabs>
          <b-tab title="Detail">
            <b-container class="no-gutters bg-white">
              <div class="py-3">
                <b-row>
                  <b-col>
                    <InputText
                      textFloat="Name"
                      placeholder="Name"
                      type="text"
                      name="name"
                      isRequired
                      :isValidate="$v.form.name.$error"
                      :v="$v.form.name"
                      v-model="form.name"
                    />
                  </b-col>
                  <b-col>
                    <InputText
                      textFloat="Display Name"
                      placeholder="Display Name"
                      type="text"
                      name="display"
                      isRequired
                      :isValidate="$v.form.display.$error"
                      :v="$v.form.display"
                      v-model="form.display"
                    />
                  </b-col>
                  <div class="break-normal"></div>
                  <b-col>
                    <ColorPicker
                      textFloat="Background Color"
                      v-model="form.bg_color"
                      :value="form.bg_color"
                      name="bg-color"
                    />
                  </b-col>
                  <b-col
                    ><ColorPicker
                      textFloat="Font Color"
                      v-model="form.font_color"
                      :value="form.font_color"
                      name="font-color"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <label class="label">Entry Point</label>
                    <b-form-checkbox
                      class="mt-1"
                      v-model="form.is_entry_point"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Entry Point
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <InputText
                      textFloat="Baht Per Point"
                      placeholder="Baht Per Point"
                      type="number"
                      name="baht_per_point"
                      isRequired
                      :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                      :v="$v.form.baht_per_point"
                      v-model="form.baht_per_point"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <InputText
                      textFloat="Upgrade Baht"
                      placeholder="Upgrade Baht"
                      type="number"
                      name="upgrade_baht"
                      :v="$v.form.upgrade_baht"
                      :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                      v-model="form.upgrade_baht"
                      :isValidate="requiredUpgradeBaht ? true : false"
                      :class="requiredUpgradeBaht ? 'mb-0' : ''"
                    />
                    <div v-if="requiredUpgradeBaht">
                      <span class="text-error">Please Input.</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="d-flex align-items-center">
                      <span class="label">Upgrade Tier</span>
                    </div>
                    <InputSelect
                      name="Upgrade Tier"
                      isRequired
                      v-model="form.upgrade_tier_id"
                      v-bind:options="upgradeTier"
                      valueField="id"
                      textField="name"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option :value="0"
                          >-- Please select value --</b-form-select-option
                        >
                      </template>
                    </InputSelect>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <InputText
                      textFloat="Maintain Baht"
                      placeholder="Maintain Baht"
                      type="number"
                      name="maintain_baht"
                      :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                      v-model="form.maintain_baht"
                    />
                  </b-col>
                  <b-col>
                    <div class="d-flex align-items-center">
                      <span class="label">Downgrade Tier</span>
                    </div>
                    <InputSelect
                      title=""
                      name="Downgrade Tier"
                      isRequired
                      v-model="form.downgrade_tier_id"
                      v-bind:options="upgradeTier"
                      valueField="id"
                      textField="name"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option :value="0"
                          >-- Please select value --</b-form-select-option
                        >
                      </template>
                    </InputSelect>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Expire conditions"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-form-radio-group
                        id="expire-options"
                        v-model="form.expire_condition_id"
                        :options="expire_options"
                        :aria-describedby="ariaDescribedby"
                        :disabled="form.downgrade_tier_id == ''"
                        name="expire-options"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <InputText
                      textFloat="Expire Period"
                      placeholder="0"
                      type="number"
                      name="membership_period"
                      v-model="form.membership_period"
                      :disabled="
                        form.expire_condition_id == 2 ||
                        form.downgrade_tier_id == ''
                      "
                      :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 3);'"
                    />
                  </b-col>
                  <b-col cols="12" class="mb-3">
                    <b-form-checkbox
                      class="mt-1"
                      v-model="form.total_spending_carry_on"
                      name="total-spending"
                      :value="1"
                      :unchecked-value="0"
                    >
                      Total spending in this tier will be includes in next tier
                      when upgrade
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <UploadFile
                      textFloat="Icon Status"
                      placeholder="Please Select a File To Upload."
                      format="image"
                      name="thumbnail"
                      :fileName="form.icon"
                      :accept="acceptType"
                      text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                      isRequired
                      v-on:onFileChange="onImageChange"
                      :v="$v.form.icon"
                      :isValidate="$v.form.icon.$error"
                    />
                    <PreviewBox
                      v-on:delete="onDeleteImage"
                      :disabled="isDisable"
                      :showPreviewType="0"
                      :showPreview="showPreview"
                      :isLoadingImage="isLoadingImage"
                      :ratioType="1"
                    />
                  </b-col>
                </b-row>
              </div> </b-container
          ></b-tab>
          <b-tab title="Information">
            <b-container class="no-gutters bg-white">
              <div class="py-3">
                <TextEditorsTiny
                  textFloat="Description"
                  :rows="4"
                  :value="form.description"
                  :name="'description_' + 1"
                  placeholder="Type something..."
                  @onDataChange="(val) => (form.description = val)"
                />
              </div>
            </b-container>
          </b-tab>
        </b-tabs>
        <FooterAction @submit="saveForm()" routePath="/membertier" />
      </b-form>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
import ColorPicker from "@/components/inputs/InputColorPicker";
export default {
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    UploadFile,
    PreviewBox,
    ColorPicker,
  },
  validations: {
    form: {
      name: { required },
      baht_per_point: { required },
      icon: { required },
      display: { required },
      membership_period: {
        // required,
        // minValue: minValue(0),
      },
      maintain_baht: {
        // required,
        // minValue: minValue(0),
      },
    },
  },

  data() {
    return {
      isLoading: false,
      noPleaseSelect: true,
      form: {
        name: "",
        expire_condition_id: 1,
        membership_period: 0,
        is_entry_point: 0,
        baht_per_point: 0,
        upgrade_tier_id: 0,
        upgrade_baht: 0,
        downgrade_tier_id: 0,
        membership_period: 0,
        maintain_baht: 0,
        total_spending_carry_on: 0,
        icon: "",
        display: "",
        description: "",
        font_color: "#00000",
        bg_color: "#ffffff",
      },
      upgradeTier: [],
      isDisable: true,
      requiredMemberPeriod: false,
      requiredMaintainBaht: false,
      requiredUpgradeBaht: false,
      id: this.$route.params.id,
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      isLoadingImage: false,
      expire_options: [
        { text: "Days", value: 1 },
        { text: "End of Year", value: 2 },
        { text: "End Next Nth Year", value: 3 },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    await this.getDropdown();
    if (this.id !== "0") {
      await this.getMemberDetail();
    }
  },
  methods: {
    async getDropdown() {
      await this.$store.dispatch("getDropdown");
      const data = this.$store.state.memberTier.dropdown;
      if (data.result == 1) {
        this.upgradeTier = data.detail;
        this.isLoading = false;
      }
    },
    async getMemberDetail() {
      await this.$store.dispatch("getDetailMember", this.id);
      const data = this.$store.state.memberTier.detailMember;
      if (data.result === 1) {
        this.form = data.detail;
        this.showPreview = this.form.icon;
        this.isLoading = false;
      }
    },
    async saveForm() {
      try {
        this.$v.form.$touch();

        if (this.$v.form.$error) {
          return;
        }
        this.$EventBus.$emit("showLoading");
        this.form.upgrade_baht = parseInt(this.form.upgrade_baht);
        this.form.baht_per_point = parseInt(this.form.baht_per_point);
        this.form.membership_period =
          parseInt(this.form.membership_period) || 0;
        this.form.maintain_baht = parseInt(this.form.maintain_baht) || 0;
        if (this.id === "0") {
          await this.$store.dispatch("createdMemberTier", this.form);
          const data = this.$store.state.memberTier.createdMemberTier;
          if (data.result === 1) {
            this.successAlert().then(() => {
              this.$router.push("/membertier");
            });
          } else {
            this.isLoading = false;
            this.errorAlert(data.message);
          }
        } else {
          let reqBody = {
            form: this.form,
            id: this.id,
          };
          await this.$store.dispatch("updateMemberTier", reqBody);
          const data = this.$store.state.memberTier.updateMember;
          if (data.result === 1) {
            this.successAlert().then(() => {
              this.$router.push("/membertier");
            });
          } else {
            this.isLoading = false;
            this.errorAlert(data.message);
          }
        }
        this.$EventBus.$emit("hideLoading");
      } catch (error) {
        this.$EventBus.$emit("hideLoading");
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.icon = this.images;
        this.showPreview = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.icon = "";
      this.showPreview = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  color: var(--font-main-color);
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  height: 31px;
}
.label {
  color: var(--font-main-color);
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.delete-icon {
  top: 0;
  right: 0;
}
</style>
